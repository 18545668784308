@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    position: absolute;
    top: 38%;
    right: 0;
    .arrowBtn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      & svg {
        transform: rotate(-90deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  // grid-template-columns: 24px 255px 139px 155px 128px 100px 100px;
  grid-template-columns: minmax(215px, 350px) minmax(185px, 240px) minmax(185px, 260px) minmax(185px, 115px) 30px;
  & .avatarWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 24px;
    overflow-wrap: break-word;

    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
    }

    & .name {
      @include text_staff_bold;
      span {
        display: block;
        @include text_staff_subtitle;
      }
    }
  }

  & .ownerLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }
  }
  & .businessLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessTypeWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .revenueWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .employeesWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
}
.acceptButtons {
  display: flex;
  gap: 5px;
}
