@import "../../scss/main.scss";

.postBody {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 20px 0;
  // margin-top: 25px;
  .image {
    width: 114px;
    height: 114px;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
.infoBody {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  p {
    @include text_staff_regular;
    font-size: 13px;
    line-height: 20px;
    margin: 14px 0 10px 0;
  }
}
.tagBody {
  display: flex;
  gap: 5px;
}
.headerBody {
  display: flex;
  justify-content: space-between;
  h5 {
    @include text_staff_bold;
    margin-bottom: 15px;
  }
  .location {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      @include text_form_label;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.dividerX {
  background: $grayE4;
  width: 100%;
  height: 1px;
  // margin-top: 20px;
}
