@import "../../scss/main.scss";
.statisticBody {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 35px;
}
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 200px 0;
  height: 70%;
}
.commonInfo {
  position: relative;
  display: flex;
  // justify-content: space-between;
  border-top: $grayE4;
  border-bottom: $grayE4;
  border: 1px;
  gap: 30px;
}
.dividerLeft {
  display: none;
  width: 1px;
  height: 100%;
  position: absolute;
  left: -15px;
  top: 0;
  background: $grayE4;
}
.dividerRight {
  display: none;
  width: 1px;
  height: 100%;
  position: absolute;
  right: -15px;
  top: 0;
  background: $grayE4;
}
.statisticInfo {
  flex: 1 1 auto;
  padding: 20px 0;
  position: relative;
  &:nth-child(odd) {
    .dividerLeft {
      display: block;
    }
    .dividerRight {
      display: block;
    }
  }
  h4 {
    font-size: 24px;
    line-height: 32px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
}
.dividerTop {
  height: 1px;
  background: $grayE4;
  // width: 105.7%;
  width: 100%;
  position: absolute;
  top: 0;
  // left: -31px;
  left: 0;
}
.dividerBottom {
  height: 1px;
  background: $grayE4;
  // width: 105.7%;
  width: 100%;
  position: absolute;
  bottom: 0;
  // left: -31px;
  left: 0;
}
.titleProgress {
  @include text_statistic_regular;
}
.progressWrapper {
  display: grid;
  // grid-template-columns: repeat(auto-fill, auto);
  // grid-template-columns: auto auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}
.radioBody {
  display: flex;
  justify-content: flex-end;
}
.chartBody {
  // flex: 1 1 100%;
  // flex: 1;
  // flex-basis: 100%;
  // flex-shrink: initial;
  width: 100%;
}
.statistic {
  flex-shrink: initial;
  flex: 0 1 auto;
}
