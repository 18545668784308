@import "./../../scss/main.scss";
.userInfo {
  display: flex;
  flex-direction: column;
  max-width: 285px;
  gap: 15px;
}
.userProfile {
  display: flex;
  gap: 10px;
  .image {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .userName {
    @include text_staff_bold;
    font-size: 14px;
  }
  .personalInfo {
    display: flex;
    flex-direction: column;
    span {
      display: block;
    }
    .userLocation {
      @include text_staff_subtitle;
      font-size: 14px;
    }
  }
}
.userSection {
  display: flex;
  flex-direction: column;
  span {
    display: block;
  }
  .title {
    @include text_staff_subtitle;
    font-size: 14px;
  }
  .subtitle {
    @include text_staff_bold;
    font-size: 14px;
  }
}
.dividerX {
  height: 1px;
  width: 100%;
  background: $grayE4;
}
