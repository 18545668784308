@import "../../scss/main.scss";

.radio {
  padding: 8px 18px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include text-menu;
  transition: 0.3s;
  &:hover {
    background: $grayE4;
  }
  input {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}
.selected {
  padding: 8px 18px;
  background: $primary;
  display: flex;
  align-items: center;
  border-radius: 12px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include text-menu_selected;
  transition: 0.3s;

  &:hover {
    opacity: 0.9;
  }
  input {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}
.tag {
  margin-left: 8px;
}
