@import "../../scss/main.scss";

.checked {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  background: $primary;
  border-radius: 4px;
  // padding: 2.26px;
  // padding: 2.78px;
  cursor: pointer;
  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: $white;
  }
}
.mainCheckBoxInner {
  display: none;
  position: absolute;
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 3px;
}
.checkedMain {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background: $primary;
  border-radius: 4px;
  cursor: pointer;
  padding: 2px;
  & svg {
    fill: $white;
  }
  position: relative;
  & .mainCheckBoxInner {
    display: block;
  }
}
.unChecked {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  background: $white;
  border: 2px solid $grayE4;
}
