@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 16px;
  background-color: $grayE4;
  cursor: pointer;
}
.activeWrapper {
  background-color: $primary;
}

.text {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  font-family: $secondary-font;
}
.activeText {
  color: $white;
}
