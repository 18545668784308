@import "./../../../scss/main.scss";
.wrapper {
  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 20px;

    & .avatar {
      width: 78px;
      height: 78px;
      overflow: hidden;
      border-radius: 100px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    & .nameWrapper {
      & .name {
        @include h1;
        margin: 0;
      }

      & .location {
        @include text_staff_subtitle;
        color: $grayBD;
        margin: 0;
      }
    }

    & .followersStatsWrapper {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: auto;
      margin-right: 50px;
      & .divider {
        width: 1px;
        height: 25px;
        background-color: $grayBD;
      }
      & .block {
        & .counter {
          @include h1;
        }

        & .subtitle {
          @include text_staff_regular;
          color: $grayBD;
        }
      }
    }
    & .socialbtnWrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      & .socialBtn {
        cursor: pointer;
      }
    }
  }

  & .tabWrapper {
    display: flex;
    gap: 2px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  & .profileInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    padding-bottom: 20px;
    border-bottom: 2px solid $shadow;
    & .profileBlock {
      flex: 1;
      & .profleTitle {
        @include h3;
        margin-bottom: 10px;
      }
      & .date {
        @include text_small_bold;
        color: $primary;
      }
    }
  }
}
