@import "./../../../../scss/main.scss";
.avatarBody {
}
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.businessName {
  margin-top: 20px;
}
.profileSection {
  span {
    display: block;
    @include text_form_label;
    margin-bottom: 10px;
  }
  a {
    margin: 0;
    text-decoration: none;
    color: $primary;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
}
.profileBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.avatarBody {
  .avatar {
    width: 100px;
    height: 100px;
  }
}
.avatarInfoBody {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  span {
    @include text_form_label;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    cursor: pointer;
    &:hover {
      color: $black15;
    }
  }
}
.deleteBody {
  color: $red !important;
  &:hover {
    color: rgb(204, 0, 0) !important;
  }
}
.dividerX {
  height: 1px;
  background: $grayE4;
  width: 100%;
  margin: 20px 0;
}
.formSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.locationForm {
  // input {
  //   width: 91% !important;
  // }
}
.button {
  margin-top: 40px;
  width: 135px;
  height: 48px;
}
.commonError {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin: 10px 0 0 0;
  margin-bottom: 10px;
}
.multiSelect {
  margin-top: 10px;
}
