@import "../../scss/main.scss";

.infoBody {
  display: flex;
  gap: 5px;
  align-items: center;
  .logo {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  flex-direction: column;
  gap: 50px;
  height: 100vh;
  padding-top: 50px;

  & .signInForm {
    width: 460px;
    background: #ffffff;
    box-shadow: 0px 15px 80px rgba(227, 230, 236, 0.8);
    border-radius: 24px;
    padding: 30px;

    & .input {
      margin-bottom: 20px;
    }

    & .checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }
  }
}

.signIn__title {
  @include h1;
}
.commonError {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin: 10px 0 0 0;
}
