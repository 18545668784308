@font-face {
  font-family: "Inter";
  src: local("Inter Regular"), local("Inter-Regular"), url("Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Semi Bold"), local("Inter-SemiBold"), url("Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Bold"), local("Inter-Bold"), url("Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: local("Inter Medium"), local("Inter-Medium"), url("Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "EudoxusSans";
  src: local("EudoxusSans Bold"), local("EudoxusSans-Bold"), url("EudoxusSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EudoxusSans";
  src: local("EudoxusSans Regular"), local("EudoxusSans-Regular"), url("EudoxusSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
