//fonts
$primary-font: "EudoxusSans", "sans-serif";
$secondary-font: "Inter", "sans-serif";

//colors
$white: #ffffff;
$black: #000000;
$black15: #141415;
$textMain: #54585f;
$primary: #236bfe;
$additional: #dee9ff;
$primaryLight: #e7deff;
$grayE4: #e4e4e4;
$grayBD: #b2b3bd;
$gray91: #808191;
$shadow: #e3e6ec;
$deactive: #b2b3bd;
$red: #ff3c3c;
$green: #63cd5a;
$green7A: #7fba7a;
