@import "../../scss/main.scss";

.radio {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include text-menu;
  color: $black15;
  transition: 0.3s;
  margin: 15px 0;
  input {
    cursor: pointer;
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $grayE4;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}
.selected {
  display: flex;
  gap: 10px;
  align-items: center;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include text-menu;
  color: $black15;
  transition: 0.3s;
  margin: 15px 0;
  // span {
  //   font-size: 14px;
  //   line-height: 18px;
  // }
  input {
    flex: 0 0 auto;
    &:hover {
      opacity: 0.9;
    }
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: solid 5px $primary;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}
