@import "./../../scss/main.scss";
.tag {
  @include tag-primary;
  font-weight: 600;
  padding: 4px 8px;
  font-family: $primary-font;
  background: $additional;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedTag {
  @include tag-activate;
  font-family: $primary-font;
  font-size: 12px;
  line-height: 16px;
  color: $primary;
  padding: 4px 8px;
  background: $white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
