@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    position: absolute;
    top: 38%;
    right: 0;
    .arrowBtn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      & svg {
        transform: rotate(-90deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.wrapperExplorer {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  // grid-template-columns: 24px 255px 139px 155px 128px 100px 100px;
  grid-template-columns:
    24px minmax(140px, 324px) minmax(50px, 160px) minmax(50px, 172px) minmax(100px, 210px) minmax(100px, auto)
    80px;
  & .avatarWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 24px;
    overflow-wrap: break-word;

    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
    }

    & .name {
      @include text_staff_bold;
      span {
        display: block;
        @include text_staff_subtitle;
      }
    }
  }

  & .ownerLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessTypeWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .revenueWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .employeesWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  // grid-template-columns: 24px 255px 139px 155px 128px 100px 100px;
  grid-template-columns:
    24px minmax(172px, 286px) minmax(120px, 200px) minmax(90px, 140px) minmax(80px, 140px) minmax(100px, 110px)
    minmax(100px, auto) 80px;
  & .avatarWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 24px;
    overflow-wrap: break-word;

    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
    }

    & .name {
      @include text_staff_bold;
      span {
        display: block;
        @include text_staff_subtitle;
      }
    }
  }

  & .ownerLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .businessTypeWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .revenueWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .employeesWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
}
.acceptButtons {
  display: flex;
  gap: 5px;
}
