@import "../../scss/main.scss";

.subtitle {
  @include text_statistic_regular;
  margin: 0;
}
.infoPerDay {
  @include text_statistic_bold;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1px;
  margin: 0;
}
.divider {
  height: 1px;
  background: $grayE4;
  margin: 10px 0;
}
.today {
  @include text_statistic_regular;
  line-height: 20px;
  font-size: 13px;
}
.todayWrapper {
  display: flex;
  gap: 10px;
  h5 {
    @include text_statistic_bold;
    margin: 0;
  }
}
.statsBody {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  gap: 5px;
  p {
    color: $green;
    margin: 0;
  }
}
.arrowBody {
  background: $green;
  width: 16px;
  height: 16px;
  flex-grow: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowUp {
  fill: white;
}
