@import "../../scss/main.scss";

.wrapper {
  width: 99%;
  // height: 300px;
}

.chartsControls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70%;
  margin: 90.5px 0;
}

.activeControlsBtn {
  padding: 8px 18px;
  background: $primary;
  display: flex;
  align-items: center;
  border-radius: 12px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include text-menu_selected;
  transition: 0.3s;

  &:hover {
    opacity: 0.9;
  }
  input {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
}

.tooltipWrapper {
  padding: 5px 15px;
  border-radius: 16px;
  background: $black15;
  border: none;
  outline: none;
  &:focus {
    outline: none;
    border: none;
  }
  &:active {
    outline: none;
    border: none;
  }

  & .label {
    @include text_x_small_bold;
    font-size: 12px;
    color: $white;
  }

  & .data {
    @include text_x_small_bold;
    color: $white;
    text-align: center;
  }
}

.contorlsBtn {
  padding: 8px 18px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  @include text-menu;
  transition: 0.3s;
  &:hover {
    background: $grayE4;
  }
  input {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
  cursor: pointer;
}

.activeControlsBtnText {
  font-family: $primary-font;
  color: $white;
}
