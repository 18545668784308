@import "../../../scss/main.scss";
.avatarBody {
}
.profileBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.avatarBody {
  .avatar {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
  }
}
.avatarInfoBody {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  margin-top: 10px;
  span {
    @include text_form_label;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.3px;
    cursor: pointer;
    &:hover {
      color: $black15;
    }
  }
}
.deleteBody {
  color: $red !important;
  &:hover {
    color: rgb(204, 0, 0) !important;
  }
}
.dividerX {
  height: 1px;
  background: $grayE4;
  width: 100%;
  margin: 20px 0;
}
.formSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.button {
  margin-top: 20px;
  width: 135px;
  height: 48px;
}

.emailAccount {
  text-align: center;
  margin-top: 10px;
  span {
    @include text_form_label;
  }
  a {
    margin: 0;
    text-decoration: none;
    color: $primary;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
  }
}
.commonError {
  font-size: 11px;
  color: $red;
  line-height: 18px;
  margin: 10px 0 0 0;
  margin-bottom: 10px;
}
