@import "../../scss/main.scss";
.infoWrapper {
  display: flex;
  justify-content: space-between;
}
.title {
  @include text_statistic_bold;
  font-size: 16px;
  line-height: 20px;
}
.statusPercent {
  font-family: $secondary-font;
  @include text_statistic_regular;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.subtitle {
  @include text_statistic_regular;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 12px;
}
.progressBar {
  background: $grayE4;
  width: 100%;
  height: 8px;
  border-radius: 4px;
}
.fill {
  background: $primary;
  height: 8px;
  border-radius: 4px;
}
.infoBody {
  display: flex;
  flex-direction: column;
}
.progressBar {
  align-self: flex-end;
}
.infoContentBody {
  flex: 1 1 auto;
}
