@import "../../scss/main.scss";

.businessBody {
  position: relative;
  & .select {
    position: absolute;
    top: 38%;
    right: 0;
    .arrowBtn {
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: $primary;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      & svg {
        transform: rotate(-90deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.wrapper {
  border-bottom: 1px solid $shadow;
  padding: 10px 0;
  display: flex;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  position: relative;
  &:hover {
    background: rgb(250, 250, 250);
  }
  display: grid;
  // grid-template-columns: 24px minmax(140px, 324px) minmax(50px, 312px) minmax(50px, 336px) 30px;
  grid-template-columns: minmax(140px, 324px) minmax(50px, 312px) minmax(50px, 336px) 30px;

  & .avatarWrapper {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: 24px;
    overflow-wrap: break-word;

    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
    }

    & .name {
      @include text_staff_bold;
      span {
        display: block;
        @include text_staff_subtitle;
      }
    }
  }

  & .ownerLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      font-size: 14px;
      line-height: 24px;
      color: $black15;
    }
  }
  & .businessLocationWrapper {
    & p {
      overflow-wrap: break-word;

      @include text_staff_bold;
      color: $black15;
    }
  }
  & .businessTypeWrapper {
    flex: 1.6 1 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .revenueWrapper {
    flex: 0.5 0 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
  & .employeesWrapper {
    flex: 1 1 auto;

    & p {
      overflow-wrap: break-word;

      @include text_staff_regular;
      color: $black15;
    }
  }
}
.select {
  cursor: pointer;
  transition: 0.3s;
  &:hover svg {
    scale: 1.2;
  }
  position: relative;
  .popap {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: 0;
    .edit {
      margin: 0;
      color: $black15 !important;
      padding: 5px 10px;
      border-radius: 10px;
      text-align: center;
      &:hover {
        background: rgb(235, 235, 235, 0.6);
      }
    }
    .delete {
      margin: 0;
      color: $red !important;
      padding: 5px 10px;
      border-radius: 10px;
      &:hover {
        background: rgb(235, 235, 235, 0.6);
      }
    }
  }
}
