@import "../../scss/main.scss";
.tabWrapper {
  display: flex;
  gap: 1px;
  margin-bottom: 28px;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  svg {
    fill: white;
  }
}
.wrapperExplorer {
  max-width: 1520px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include text_statistic_bold;
    }
  }
  & .contentContainer {
    & .searchContainer {
      display: flex;
      align-items: center;
      margin: 28px 0;
    }

    & .sectionHeader {
      // display: flex;
      position: relative;
      display: grid;
      overflow-wrap: normal;
      grid-template-columns:
        24px minmax(140px, 324px) minmax(50px, 160px) minmax(50px, 172px) minmax(100px, 210px) minmax(100px, auto)
        58px;
      align-items: center;
      padding-bottom: 7px;
      border-bottom: 1px solid $shadow;
      & .nameBlock {
        flex: 5 1 auto;
        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        margin-left: 24px;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
      & .industryBlock {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }

      & .locationBlock {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }

      & .sizeBlock {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
    }
    & .reportsContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
    & .paginationContainer {
      margin-top: 30px;
      display: flex;
      gap: 8px;

      & .nextBtn {
        cursor: pointer;
        background-color: $primary;
        padding: 14px 30px;
        border-radius: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        font-family: $secondary-font;
      }
    }
  }
}
.wrapper {
  max-width: 1520px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include text_statistic_bold;
    }
  }
  & .contentContainer {
    & .searchContainer {
      display: flex;
      align-items: center;
      margin: 28px 0;
    }

    & .sectionHeader {
      // display: flex;
      position: relative;
      display: grid;
      overflow-wrap: normal;
      grid-template-columns:
        24px minmax(172px, 286px) minmax(120px, 200px) minmax(90px, 140px) minmax(80px, 140px) minmax(100px, 110px)
        minmax(100px, auto) 60px;
      align-items: center;
      padding-bottom: 7px;
      border-bottom: 1px solid $shadow;
      & .nameBlock {
        flex: 5 1 auto;
        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        margin-left: 24px;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
      & .industryBlock {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }

      & .locationBlock {
        flex: 1 1 auto;

        display: flex;
        align-items: center;
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }

      & .sizeBlock {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        p {
          white-space: nowrap;
        }
        @include text_x_small_extrabold;
        color: $grayBD;
        gap: 3px;

        & svg {
          stroke: $grayBD;
          cursor: pointer;
        }
        p {
          cursor: pointer;
        }
      }
    }
    & .reportsContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
    & .paginationContainer {
      margin-top: 30px;
      display: flex;
      gap: 8px;

      & .nextBtn {
        cursor: pointer;
        background-color: $primary;
        padding: 14px 30px;
        border-radius: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $white;
        font-family: $secondary-font;
      }
    }
  }
}
.activeBlockAZ {
  color: $black15 !important;
  svg {
    fill: $black15 !important;
  }
}
.activeBlockZA {
  color: $black15 !important;
  transition: 0.3s;
  svg {
    rotate: (-180deg) !important;
    fill: $black15 !important;
  }
}
