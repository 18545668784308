@import "../../scss/main.scss";

.input {
  position: relative;
  &__mainGoogle {
    width: 92%;
    background-color: rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 18px;
    line-height: 22px;
    caret-color: $primary;
    color: #000;
    @include text_small_bold;
    &:disabled {
      cursor: default;
      color: $grayBD;
    }
    &::placeholder {
      color: $black15;
    }
    border: 2px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
      border-radius: 8px 8px 0 0;
    }
  }
  &__main {
    width: 92%;
    background-color: rgba(228, 228, 228, 0.25);
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 12px 18px;
    line-height: 22px;
    caret-color: $primary;
    color: #000;
    @include text_small_bold;
    &::placeholder {
      color: $black15;
    }
    border: 2px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
    }
    &:disabled {
      background: rgba(228, 228, 228, 0.49776) !important;
      color: $grayBD !important;
      @include text_form_label;
    }
  }
  span {
    display: block;
    color: $black15;
    margin-bottom: 10px;
    @include text_form_label;
  }
  p {
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
  .show {
    position: absolute;
    top: 44px;
    right: 15px;
    @include text_form_label;
    letter-spacing: 1.2px;
    cursor: pointer;
    &:hover {
      color: $black15;
    }
  }
}
.withIcon {
  padding-left: 50px;
  &::placeholder {
    padding-left: 0px;
    @include text_form_label;
    font-size: 14px;
  }
}

.inputWrapper {
  position: relative;
  input {
    &::placeholder {
      @include text_form_label;
    }
  }
  .cancelRemoveInput {
    position: absolute;
    top: 15px;
    right: 0px;
    cursor: pointer;
  }

  // .pac-item {
  //   background: green !important;
  //   &::after {
  //     display: none !important;
  //   }
  // }
}
.icon {
  position: absolute;
  top: 12px;
  left: 15px;
}
.error {
  font-size: 11px;
  color: $red;
  line-height: 18px;
}

.option {
  height: 0;
  visibility: hidden;
  overflow: hidden;
  border-width: 2px;
  // border-style: solid;
  border-color: $primary;
  box-sizing: border-box;
}
.optionOpen {
  position: absolute;
  margin: -2px 0 0 0;
  width: 100%;
  visibility: visible;
  // transition: 0.3s;
  height: auto;
  z-index: 10;
  border-width: 2px;
  border-style: solid;
  border-color: $primary;
  box-sizing: border-box;
  // padding: 18px 23px;
  background: rgb(250, 250, 250);
  max-height: 150px;
  overflow-y: auto;
  padding-left: 10px;
  border-radius: 0 0 8px 8px;
}
.lastOption {
  visibility: visible;
  height: auto;
  // padding: 5px 23px;
  background: rgb(250, 250, 250);
  border-radius: 0 0 8px 8px;
}
.optionItem {
  list-style-type: none;
  cursor: pointer;
  span {
    @include text_small_bold;
    font-weight: 700;
    margin: 10px 20px;
  }
}
.googleOpen_wrapper {
  input {
    width: 100% !important;
    box-sizing: border-box;
  }
  &_open {
    input {
      border-radius: 8px 8px 0 0;
    }
  }
}
