body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pac-container {
  background-color: #fff;
  position: absolute !important;
  top: -2px;
  z-index: 1000;
  transition: 0.1s;
  width: 110%;
  background-color: rgba(228, 228, 228, 0.25) !important;
  margin-top: -2px;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  font-weight: 500;
  max-height: 150px;
  overflow-y: auto;
  line-height: 22px;
  caret-color: #236bfe;
  color: #000;
  font-family: "EudoxusSans", "sans-serif";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  border-width: 2px;
  border-style: solid;
  border-color: #236bfe;
}

.pac-logo:after {
  display: none;
}
.hdpi.pac-logo:after {
}
.pac-item {
  cursor: pointer;
  margin: -2px 0 0 0;
  width: 102%;
  visibility: visible;
  transition: 0.3s;
  height: auto;
  z-index: 10;
  box-sizing: border-box;
  padding: 18px 24px;
  background: rgb(250, 250, 250);
  max-height: 150px;
  overflow-y: auto;
}
.pac-item:hover {
  background-color: #fafafa;
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ebf2fe;
}
.pac-matched {
  font-weight: 700;
}
.pac-item-query {
  font-size: 13px;
  padding-right: 3px;
  color: #000;
}
.pac-icon {
  display: none;
}
.hdpi .pac-icon {
  display: none;
}
.pac-icon-search {
  background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
  background-position: -18px -1px;
}
.pac-icon-marker {
  background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
  background-position: -18px -161px;
}
.pac-placeholder {
  color: gray;
}
.go3958317564 {
  justify-content: flex-start;
}
