@import "../../../../scss/main.scss";
.profileSection {
  margin: 0 0 25px 0;
  .input {
    width: 447px;
  }
}
.postBody {
  width: 100%;
}
.postWrapper {
  // padding: 0 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: rgba(233, 233, 233, 0.201);
  }
}
.paginationContainer {
  margin-top: 30px;
  display: flex;
  gap: 8px;

  & .nextBtn {
    cursor: pointer;
    background-color: $primary;
    padding: 14px 30px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: $white;
    font-family: $secondary-font;
  }
}
.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
