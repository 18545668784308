@import "../../scss/main.scss";

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  width: 100%;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.disabled {
  background-color: $grayE4;
}

.filled {
  background-color: $primary;
  color: $white;
  transition: all 0.2s linear;

  &:hover {
    opacity: 0.9;
  }
}

.outlined {
  border-width: 2px;
  border-style: solid;
  line-height: 16px;
  border-color: $grayE4;
  background-color: $white;

  width: 100%;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 14px 0;
  @include text-menu_selected;
}
