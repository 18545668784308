@import "../../scss/main.scss";

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: "1000";
  overflow-y: auto;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 980px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
    }
    svg {
      cursor: pointer;
    }
  }
}
.tagBody {
  display: flex;
  gap: 5px;
}
.mainInfo {
  margin-bottom: 20px;
  p {
    @include h1;
    margin: 0 0 5px 0;
  }
  .tagContainer {
    display: flex;
    justify-content: space-between;
    span {
      @include text_x_small_medium;
    }
  }
}
.content {
  max-width: 691px;
  p {
    @include text_staff_subtitle;
    color: $black15;
  }
}
.modalBody {
  display: flex;
  gap: 50px;
}
.postModalBody {
  flex: 1 1 auto;
}
.userInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.userProfile {
  display: flex;
  gap: 10px;
  .image {
    width: 44px;
    height: 44px;
    img {
      width: 100%;
    }
  }
  .userName {
    @include text_staff_bold;
  }
  .personalInfo {
    display: flex;
    flex-direction: column;
    span {
      display: block;
    }
    .userLocation {
      @include text_staff_subtitle;
    }
  }
}
.userSection {
  display: flex;
  flex-direction: column;
  span {
    display: block;
  }
  .title {
    @include text_staff_subtitle;
  }
  .subtitle {
    @include text_staff_bold;
  }
}
.dividerX {
  height: 1px;
  width: 100%;
  background: $grayE4;
}
