@import "../../scss/main.scss";

.overlay {
  // display: flex;
  // justify-content: center;
  // position: absolute;
  // // height: 100vw;
  // overflow: auto;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // background: rgba(0, 0, 0, 0.58);
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.58);
  z-index: "1000";
  overflow-y: auto;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 700px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    h5 {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
    }
    svg {
      cursor: pointer;
    }
  }
}
.tagBody {
  display: flex;
  gap: 5px;
}

.dividerX {
  height: 1px;
  width: 100%;
  background: $grayE4;
}
.approveBody {
  flex: 1 1 auto;
}
.approveSection {
  background: rgba(228, 228, 228, 0.25);
  border-radius: 15px;
  padding: 25px 20px;
  margin-bottom: 20px;
  .radio {
    display: flex;
    flex-direction: column;
    span {
      font-size: 16px;
    }
  }
}
.subRadio {
  margin: 0 30px;
  p {
    margin: 0;
    @include text_staff_subtitle;
    color: #54585f;
  }
  span {
    font-weight: 400;
    font-size: 13px;
  }
}
.button {
  display: flex;
  justify-content: flex-end;
  button {
    width: 163px;
  }
}

.reportersContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // margin-bottom: 22px;
  .staffBody {
    .staff {
      margin-left: -15px;
      margin-top: -6px;
    }
    display: flex;
    flex-direction: column;

    span {
      @include text_form_label;
      color: $grayBD;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      margin-bottom: -5px;
    }
  }
  .date {
    @include text_staff_regular;
    font-size: 14px;
    line-height: 24px;
  }
}
.descriptionBody {
  margin-bottom: 20px;
  width: 75%;
  span {
    @include h2;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }
  p {
    margin: 5px 0 0 0;
    @include text_staff_subtitle;
    color: #54585f;
  }
}
.attachment {
  margin-bottom: 22px;
  .attachName {
    margin-bottom: 10px;
  }
  span {
    @include h2;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
  .attachmentBody {
    width: 100px;
    height: 120px;
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.category {
  @include h1;
  margin: 22px 0 10px 0;
}
