@import "../../scss/main.scss";

.avatarWrapper {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: 24px;
  overflow-wrap: break-word;
  margin: 16px;

  & .avatar {
    overflow: hidden;
    width: 44px;
    flex-shrink: inherit;
    height: 44px;
    border-radius: 100px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & .name {
    @include text_staff_bold;
    margin: 0;
    span {
      display: block;
      @include text_staff_subtitle;
    }
  }
}

.avatarWrapperSmall {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-wrap: break-word;
  margin: 8px;
  & .avatar {
    overflow: hidden;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & .name {
    @include text_staff_bold;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    span {
      display: block;
      @include text_staff_subtitle;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }
}
