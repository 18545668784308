@import "../../scss/main.scss";
.activeButton {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $green;
  transition: 0.3s;
  &:hover {
    background: rgb(0, 156, 0);
  }
}
.declineButton {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $red;
  margin-right: 5px;
  transition: 0.3s;
  &:hover {
    background: rgb(217, 0, 0);
  }
}
